import React from "react";
import LineChart from "@cloudscape-design/components/line-chart";
import Box from "@cloudscape-design/components/box";
import Button from "@cloudscape-design/components/button";
import { Reading } from "../model";

export enum ReadingGraphPeriod {
    HOUR = "HOUR",
    DAY = "DAY",
    WEEK = "WEEK",
}

type ReadingsGraphProps = {
    waterReadings: Reading[];
    airReadings: Reading[];
    period: ReadingGraphPeriod;
}

type ReadingsGraphState = {
}

export class ReadingsGraph extends React.Component<ReadingsGraphProps, ReadingsGraphState> {
    private doMath() {
        let values = [...this.props.waterReadings.map(reading => +reading.value), ...this.props.airReadings.map(reading => +reading.value)];
        let max = Math.max(...values);
        let min = Math.min(...values);
        let split = max - min;
        return {
            max,
            min,
            split,
        }
    }

    public render() {
        let { max, min, split } = this.doMath();
        console.log({ min, max, split });
        // console.log(
        //     new Date(this.props.waterReadings[this.props.waterReadings.length - 1].timestamp),
        //     new Date(this.props.waterReadings[0].timestamp),
        // );
        return (
            <LineChart
                series={[
                    {
                        title: "Water Temperature",
                        type: "line",
                        data: this.props.waterReadings.map(reading => ({
                            x: new Date(reading.timestamp),
                            y: +reading.value,
                        })),
                        valueFormatter: (value) => `${value.toFixed(2)}°F`,
                    },
                    {
                        title: "Air Temperature",
                        type: "line",
                        data: this.props.airReadings.map(reading => ({
                            x: new Date(reading.timestamp),
                            y: +reading.value,
                        })),
                        valueFormatter: (value) => `${value.toFixed(2)}°F`,
                    },
                ]}
                // xDomain={[
                //     new Date(this.props.readings[this.props.readings.length - 1].timestamp),
                //     new Date(this.props.readings[0].timestamp),
                // ]}
                yDomain={[
                    Math.floor(min - (split / 2)),
                    Math.ceil(max + (split / 2)),
                ]}
                i18nStrings={{
                    xTickFormatter: e =>
                        e
                            .toLocaleDateString("en-US", {
                                month: "short",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                hour12: !1
                            })
                            .split(",")
                            .join("\n"),
                    yTickFormatter: (value) => `${value.toFixed(1)}°F`,
                }}
                ariaLabel="Single data series line chart"
                height={300}
                hideFilter
                hideLegend
                xScaleType="time"
                // xTitle="Time"
                // yTitle="Temperatures"
                empty={
                    <Box textAlign="center" color="inherit">
                        <b>No data available</b>
                        <Box variant="p" color="inherit">
                            There is no data available
                        </Box>
                    </Box>
                }
                noMatch={
                    <Box textAlign="center" color="inherit">
                        <b>No matching data</b>
                        <Box variant="p" color="inherit">
                            There is no matching data to display
                        </Box>
                        <Button>Clear filter</Button>
                    </Box>
                }
            />
        );
    }
}