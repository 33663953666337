import React, {  } from "react";
import { Router, Route } from "@djs/core";
import { AddTummyTimePage } from "./pages/AddTummyTime";
import { ViewReadingsPage } from "./pages/ViewReadings";

// const fabStyle: CSSProperties = {
//     margin: 0,
//     top: "auto",
//     left: 20,
//     bottom: 20,
//     right: "auto",
//     position: "fixed",
// };


export class App extends React.PureComponent {
    public render(): React.ReactNode {
        return (
            <Router notFoundComponent={<span children="not found" />}>
                {[<Route path="/">
                    <ViewReadingsPage />
                    {/* <Link to="/rate">
                        <Fab style={fabStyle} variant="extended" color="primary" aria-label="add">
                            <StarIcon sx={{ mr: 1 }} />
                            Rate
                        </Fab>
                    </Link> */}
                </Route>]}
                {/* <Route path="/add">
                    <AddTummyTimePage />
                </Route> */}
            </Router>
        )
    }
}
