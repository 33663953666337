import React from "react";
import { NavigationBar as CoreNavigationBar, RouterContext } from "@djs/core";
import AddIcon from "@mui/icons-material/Add";

type NavigationBarProps = {
    loading?: boolean;
    onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    noSearch?: boolean;
    titleOverride?: string;
}

type NavigationBarState = {
}

export class NavigationBar extends React.PureComponent<NavigationBarProps, NavigationBarState> {
    public render() {
        return (
            <RouterContext.Consumer>
                {(routerContext) =>
                    <CoreNavigationBar
                        key="nav-bar"
                        leftIcon={<img src={"/wx.png"} style={{ width: 24, height: 24 }} />}
                        onLeftIconClick={() => routerContext.navigateTo("/")}
                        // rightIcon={<AddIcon />}
                        // onRightIconClick={() => routerContext.navigateTo("/add")}
                        placeholder={this.props.titleOverride || (this.props.onChange ? "Search Swazzle Weather!" : "Swazzle Weather!")}
                        onChange={this.props.onChange}
                        {...this.props}
                    />
                }
            </RouterContext.Consumer>
        )
    }
}
