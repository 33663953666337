import * as React from 'react';
import { createRoot } from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { App } from './App';
import { theme, darkTheme } from './theme';
import { applyMode, Mode } from '@cloudscape-design/global-styles';


const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

let chosenTheme = theme;
if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    applyMode(Mode.Dark);
    chosenTheme = darkTheme;
    document.body.style.background = "#2f3036";
}

root.render(
    <ThemeProvider theme={chosenTheme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <App />
    </ThemeProvider>,
);
